import encryptor from '~/plugins/encryption.js'

export const state = () => ({
  loading: false,
  error: {},
  defaultStake: [50, 500, 1000, 1500, 2000, 3000],
  userConfig: undefined
})

export const mutations = {
  set_data(state, data) {
    if (data.oneClickStake.length < 6) {
      for (let index = 0; index <= 6 - data.oneClickStake.length + 1; index++) {
        data.oneClickStake.push(10000 * (index + 1))
      }
    }
    if (data.mobileStake.length < 6) {
      for (let index = 0; index <= 6 - data.mobileStake.length + 1; index++) {
        data.mobileStake.push(10000 * (index + 1))
      }
    }
    this.$auth.$storage.setUniversal('userConfig', data, true)
    state.userConfig = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getUserConfig({ commit }) {
    try {
      let userId
      if (this.$auth.loggedIn) {
        // This will stored data for 4 hours.
        if (
          sessionStorage.getItem('set_UserConfig_timezone') &&
          sessionStorage.getItem('set_UserConfig')
        ) {
          const aa = sessionStorage.getItem('set_UserConfig_timezone')
          const now = new Date().getTime()
          const distance = now - aa
          const seconds = Math.floor(distance / 1000)
          const minutes = Math.floor(seconds / 60)
          const hours = Math.floor(minutes / 60)
          if (hours >= 4) {
            sessionStorage.removeItem('set_UserConfig')
            sessionStorage.removeItem('set_UserConfig_timezone')
          }
        }
        if (process.client) {
          if (sessionStorage.getItem('set_UserConfig')) {
            const data = await JSON.parse(
              encryptor.decrypt(sessionStorage.getItem('set_UserConfig'))
            )
            commit('set_data', data)
            return
          }
        }

        userId = this.$auth.user._id
        commit('set_loading', true)
        const response = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'UserConfig/getUserConfig',
          data: { user: userId }
        })
        commit('set_data', response.data.data)
        sessionStorage.setItem(
          'set_UserConfig',
          encryptor.encrypt(JSON.stringify(response.data.data))
        )
        sessionStorage.setItem('set_UserConfig_timezone', new Date().getTime())
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async setUserConfig({ commit }, payload) {
    if (this.$auth.loggedIn) {
      payload.user = this.$auth.user._id
    }
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: 'UserConfig/setUserConfig',
        data: payload
      })
      if (response.data.value === true) {
        commit('set_data', payload)
        sessionStorage.setItem(
          'set_UserConfig',
          encryptor.encrypt(JSON.stringify(payload))
        )
        sessionStorage.setItem('set_UserConfig_timezone', new Date().getTime())
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
