import encryptor from '~/plugins/encryption.js'
// import groupBy from 'lodash/groupBy'
export const state = () => ({
  data: [],
  isEmpty: false,
  matchIds: [],
  loading: false,
  error: ''
})

export const mutations = {
  set_isEmpty(state, data) {
    state.isEmpty = data
  },
  set_matchIds(state, data) {
    state.matchIds = data
    this.commit('placeBet/set_matchIds', state.matchIds)
  },
  set_data(state, { matchData = [] }) {
    const matches = []
    if (matchData) {
      matches.push({
        tab: 'Live & Upcoming',
        icon: 'mdi-motion-play-outline',
        fixtures: matchData
      })
    }
    // if (live) {
    //   matches.push({
    //     tab: 'Live',
    //     icon: 'mdi mdi-access-point',
    //     image: require('@/assets/playicons/Live.svg'),
    //     fixtures: [...live]
    //   })
    // }
    // if (one) {
    //   matches.push({
    //     tab: '1 H',
    //     icon: 'mdi-clock',
    //     image: require('@/assets/playicons/1hr.svg'),
    //     fixtures: [...one]
    //   })
    // }
    // if (three) {
    //   matches.push({
    //     tab: '3 H',
    //     icon: 'mdi-clock-alert',
    //     image: require('@/assets/playicons/3hr.svg'),
    //     fixtures: [...three]
    //   })
    // }
    // if (twelve) {
    //   matches.push({
    //     tab: '12 H',
    //     icon: 'mdi-clock-fast',
    //     image: require('@/assets/playicons/12hr.svg'),
    //     fixtures: [...twelve]
    //   })
    // }
    // if (upcoming) {
    //   matches.push({
    //     tab: 'Upcoming',
    //     icon: 'mdi mdi-calendar-clock',
    //     fixtures: [...upcoming]
    //   })
    // }
    if (matchData) {
      if (
        this.$router.currentRoute.params &&
        this.$router.currentRoute.params.category &&
        this.$router.currentRoute.params.category !== 'horse-racing' &&
        this.$router.currentRoute.params.category !== 'greyhound-racing'
      ) {
        matches.push({ tab: 'Leagues', icon: 'mdi-format-list-checks' })
      }
    }
    // if (live || upcoming) {
    //   matches.push({ tab: 'Results', icon: 'mdi-file-check' })
    // }
    state.data = matches
  },

  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },

  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getMarketsById(
    { commit },
    {
      game,
      competitionId,
      loading = true,
      socketData,
      upComing = false,
      useCache = false
    }
  ) {
    commit('set_loading', loading)
    const compId = competitionId ? '_' + competitionId : ''
    if (useCache) {
      // This will stored data for 5mins.
      if (
        sessionStorage.getItem(`set_MarketsById_timezone_${game}${compId}`) &&
        sessionStorage.getItem(`set_MarketsById_${game}${compId}`)
      ) {
        const aa = sessionStorage.getItem(
          `set_MarketsById_timezone_${game}${compId}`
        )
        const now = new Date().getTime()
        const distance = now - aa
        const seconds = Math.floor(distance / 1000)
        const minutes = Math.floor(seconds / 60)
        if (minutes >= 5) {
          sessionStorage.removeItem(`set_MarketsById_${game}${compId}`)
          sessionStorage.removeItem(`set_MarketsById_timezone_${game}${compId}`)
          sessionStorage.removeItem(`set_MarketsById_MatchIds_${game}${compId}`)
          sessionStorage.removeItem(`set_MarketsById_IsEmpty_${game}${compId}`)
        }
      }
      if (
        process.client &&
        sessionStorage.getItem(`set_MarketsById_${game}${compId}`)
      ) {
        const data = await JSON.parse(
          encryptor.decrypt(
            sessionStorage.getItem(`set_MarketsById_${game}${compId}`)
          )
        )
        const matchIdsData = await JSON.parse(
          encryptor.decrypt(
            sessionStorage.getItem(`set_MarketsById_MatchIds_${game}${compId}`)
          )
        )
        const isEmptyData = await JSON.parse(
          encryptor.decrypt(
            sessionStorage.getItem(`set_MarketsById_IsEmpty_${game}${compId}`)
          )
        )
        if (matchIdsData.length > 0) {
          this.commit('socketRate/SET_IDS', matchIdsData)
          this.commit('socketBookmaker/SET_IDS', matchIdsData)
          // this.commit('socketMain/SET_IDS', matchIdsData)
        }
        commit('set_matchIds', matchIdsData)
        commit('set_isEmpty', isEmptyData)
        commit('set_data', data)
        commit('set_loading', false)
        return
      }
    }

    commit('set_matchIds', [])
    sessionStorage.setItem(
      `set_MarketsById_MatchIds_${game}${compId}`,
      encryptor.encrypt(JSON.stringify([]))
    )
    let userId
    try {
      // commit('set_loading', loading)
      if (loading) {
        commit('set_data', [])
        sessionStorage.setItem(
          `set_MarketsById_${game}${compId}`,
          encryptor.encrypt(JSON.stringify([]))
        )
      }
      let matchData
      if (socketData) {
        matchData = socketData
      } else {
        const { data: responseData } = await this.$axios({
          method: 'post',
          baseURL: process.env.SPORTSBOOK_API_URL,
          url: 'category/getMarketIds',
          data: {
            game,
            competitionId,
            _id: userId,
            _accessToken: this.$auth.getToken('customLocal'),
            accessLevel: 'Player',
            isFancy: false,
            upComing
          }
        })
        matchData = responseData ? responseData.data : undefined
        if (responseData && responseData.data && !responseData.data.length) {
          commit('set_isEmpty', true)
          commit('set_data', [])
          sessionStorage.setItem(
            `set_MarketsById_IsEmpty_${game}${compId}`,
            encryptor.encrypt(JSON.stringify(true))
          )
          sessionStorage.setItem(
            `set_MarketsById_${game}${compId}`,
            encryptor.encrypt(JSON.stringify([]))
          )
        }
      }
      // setting match ids for socket on
      if (matchData && matchData.length > 0 && Array.isArray(matchData)) {
        commit('set_isEmpty', false)
        sessionStorage.setItem(
          `set_MarketsById_IsEmpty_${game}${compId}`,
          encryptor.encrypt(JSON.stringify(false))
        )
        // const nonBetFairIds = []
        const matchIds = []
        matchData.forEach((market) => {
          market?.betfairId && matchIds.push(market.betfairId)
        })

        if (matchIds.length > 0) {
          this.commit('socketRate/SET_IDS', matchIds)
          this.commit('socketBookmaker/SET_IDS', matchIds)
          // this.commit('socketMain/SET_IDS', matchIds)
        }

        // if (nonBetFairIds.length > 0) {
        //   const joinRoomName = nonBetFairIds.map((eventId) => {
        //     return `uof_main_market_${eventId}`
        //   })
        //   this.commit('socketRate/SET_EVENT_IDS', joinRoomName)
        // }

        commit('set_matchIds', matchIds)
        sessionStorage.setItem(
          `set_MarketsById_MatchIds_${game}${compId}`,
          encryptor.encrypt(JSON.stringify(matchIds))
        )

        // const mapped = matchData.map((v) => {
        //   // const startTime = this.$moment(v.marketStartTime)
        //   // const currentTime = this.$moment()
        //   // const duration = this.$moment.duration(startTime.diff(currentTime))
        //   // const hours = duration.asHours().toFixed(2)
        //   // hours < 0
        //   //   ? (v.marketStart = 'started')
        //   //   : hours > 0 && hours < 1
        //   //   ? (v.marketStart = 'one')
        //   //   : hours > 1 && hours < 3
        //   //   ? (v.marketStart = 'three')
        //   //   : hours > 3 && hours < 12
        //   //   ? (v.marketStart = 'twelve')
        //   //   : (v.marketStart = 'upcoming')
        //   if (v) {
        //     v.bookMakerCustom =
        //       v && v?.betfairId && v?.betfairId?.includes('#bookmaker')
        //     if (
        //       v.gameName !== 'UOFGame' &&
        //       v.inPlayStatus &&
        //       v.inPlayStatus === 'Open'
        //     ) {
        //       v.status = 'live'
        //     } else {
        //       v.status = 'upcoming'
        //     }
        //   }
        //   return v
        // })
        // const grTime = groupBy(mapped, 'marketStart')
        // const grouped = groupBy(mapped, 'status')
        // const newData = {
        //   ...grTime,
        //   ...grouped
        // }

        if (!loading) {
          commit('set_data', [])
          sessionStorage.setItem(
            `set_MarketsById_${game}${compId}`,
            encryptor.encrypt(JSON.stringify([]))
          )
        }
        commit('set_data', { matchData })
        sessionStorage.setItem(
          `set_MarketsById_${game}${compId}`,
          encryptor.encrypt(JSON.stringify({ matchData }))
        )
      } else if (!matchData?.length) {
        commit('set_isEmpty', true)
        commit('set_data', [])
        sessionStorage.setItem(
          `set_MarketsById_IsEmpty_${game}${compId}`,
          encryptor.encrypt(JSON.stringify(true))
        )
        sessionStorage.setItem(
          `set_MarketsById_${game}${compId}`,
          encryptor.encrypt(JSON.stringify([]))
        )
      } else if (!matchData) {
        commit('set_error', 'Error getting matches')
      }
      sessionStorage.setItem(
        `set_MarketsById_timezone_${game}${compId}`,
        new Date().getTime()
      )
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
